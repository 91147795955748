import { getToken, onMessage } from "firebase/messaging";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { VAPID_KEY, messaging } from "./config/firebase-config";
import store, { persistor } from "./redux";
import { getNotification } from "../src/redux/slices/notification";
import Router from "./routes";

function App() {
console.log(VAPID_KEY,"VAPID_KEY");

  // Get FCM token , Generate Token
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY,
      });

      localStorage.setItem("fcmToken", token);
      onMessage(messaging, (payload) => {
        const { title, body, icon } = payload.notification;
        new Notification(title, {
          body,
          icon,
        });
        fetchNotification();
      });

    }
  }
  const fetchNotification = () => {
    store.dispatch(
      getNotification({
        cb(res) {
          if (res?.status === 200) {
          }
        },
      })
    );
  };

  // Req user for notification permission
  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </PersistGate>
      </Provider>
      <ToastContainer theme="dark" />
    </>
  );
}

export default App;
