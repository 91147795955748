import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    onErrorStopLoad: (state) => {
        state.loading = false;
      },
      getAllTickets: (state) => {
        state.loading = true;
      },
      setGetAllTicket: (state) => {
        state.loading = false;
      },
      getOneTickets: (state) => {
        state.loading = true;
      },
      setGetOneTicket: (state) => {
        state.loading = false;
      },
      setUpdateOneTicket: (state) => {
        state.loading = true;
      },
      updateOneTickets: (state) => {
        state.loading = false;
      },
      ticketUploadDocument: (state) => {
        state.loading = true;
      },
      setTicketUploadDocument: (state) => {
        state.loading = false;
      },
    },
  });
  
  // Action creators are generated for each case reducer function
  export const {
    onErrorStopLoad,
    getAllTickets,
    setGetAllTicket,
    setGetOneTicket,
    getOneTickets,
    updateOneTickets,
    setUpdateOneTicket,
    ticketUploadDocument,
    setTicketUploadDocument
  } = ticketSlice.actions;
  
  export default ticketSlice.reducer;
  