import React, { useEffect, useState } from "react";
import * as images from "../../utilities/images";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { getAllArticle } from "../../redux/slices/article";
import { useArticleSelector } from "../../redux/selector/article";
import { markDowntoText } from "../../utilities/common";
import moment from "moment";
import PublicationGenreModal from "../Modal/PublicationGenreModal";
import CustomModal from "../Modal/CustomModal";

const Article = () => {
  document.title = "Article";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const articleSelector = useArticleSelector();
  const { loading } = articleSelector;
  const [articleList, setArticleList] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  console.log(status,"statusstatusstatus");
  
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [allPublicationNames, setAllPublicationNames] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  // List of suggestions (replace with actual data)
  const suggestions = articleList || [];

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setCurrentPage(1);

    // Filter suggestions based on input
    const filtered = suggestions.filter((item) =>
      item?.title?.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearch(suggestion?.title);
    setFilteredSuggestions([]);
  };

  //open modal
  const handleOpenModal = (flag, data) => {
    // setDeleteId(data);
    setAllPublicationNames(data);
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // get all users
  useEffect(() => {
    let params = {
      page: currentPage,
      limit: 10,
      search: search || undefined,
      status: status || undefined,
      userId: id || undefined,
    };
    // if(status =="submittedForPublication"){
    //   params.submittedForPublication = true;
    //   delete params.status
    // }
    // if(status==="published"){
    //   params.published = true;
    //   delete params.status
    // }
    if (Boolean(search)) {
      params.search = search;
    }
    dispatch(
      getAllArticle({
        ...params,
        cb(res) {
          if (res?.data) {
            setArticleList(res?.data?.data?.data);
            setPageCount(res.data.data.total_pages);
          }
        },
      })
    );
  }, [status, search, currentPage]);

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <>
      <div className="content-wrap pb-5">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="mainHead32">Articles</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="searchSection">
          <input
            class="form-control searchInput"
            type="text"
            placeholder="Search by article name"
            value={search}
            // onChange={(e) => {
            //   setSearch(e.target.value);
            //   setCurrentPage(1);
            // }}
            onChange={handleSearchChange}
          />
          <img
            src={images.searchImg}
            alt="searchImg"
            className="img-fluid searchBarIcon"
          />
          {search && filteredSuggestions.length > 0 && (
            <ul className="autocomplete-list pointCursor">
              {filteredSuggestions.map((suggestion) => (
                <li
                  key={suggestion._id}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="autocomplete-item"
                >
                  {suggestion?.title}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="recentUserSec">
          <div className="userListStatus">
            <h4
              className={
                status === "" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("")}
            >
              All
            </h4>
            <h4
              className={
                status === "under_review"
                  ? "statusHeading active"
                  : "statusHeading"
              }
              onClick={() => setStatus("under_review")}
            >
              Pending
            </h4>
            <h4
              className={
                status === "schedule" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("schedule")}
            >
              Schedule
            </h4>
            <h4
              className={
                status === "approved" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("approved")}
            >
              Approved
            </h4>
            <h4
              className={
                status === "rejected" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("rejected")}
            >
              Rejected
            </h4>
            <h4
              className={
                status === "submittedForPublication"
                  ? "statusHeading active"
                  : "statusHeading"
              }
              onClick={() => setStatus("submittedForPublication")}
            >
              Submitted for Publication
            </h4>
            <h4
              className={
                status === "published"
                  ? "statusHeading active"
                  : "statusHeading"
              }
              onClick={() => setStatus("published")}
            >
              Published
            </h4>
          </div>
          <div className="table-responsive recentTable articleTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead createdAtHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead createdAtHead">
                    Article Name
                  </th>
                  <th scope="col" className="recentHead createdAtHead">
                    Publication
                  </th>
                  <th scope="col" className="recentHead createdAtHead">
                    Created At
                  </th>
                  {/* <th scope="col" className="recentHead">
                    Description
                  </th> */}
                  {/* <th scope="col" className="recentHead createdAtHead">
                    Posted By
                  </th> */}
                  <th scope="col" className="recentHead createdAtHead">
                    Status
                  </th>
                  <th
                    scope="col"
                    className="recentHead createdAtHead text-left"
                  >
                    ACTION
                  </th>
                </tr>
              </thead>

              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <>
                  <tbody>
                    {articleList?.length > 0 ? (
                      articleList?.map((data, idx) => {
                        // Create a temporary element to parse the HTML content
                        const tempElement = document.createElement("div");
                        tempElement.innerHTML = data.description;

                        // Extract text data by removing HTML tags
                        const extractedText =
                          tempElement.textContent || tempElement.innerText;

                        let serialNumber = (currentPage - 1) * 10 + idx + 1;
                        return (
                          <tr>
                            <td
                              className="recentSubHead table_UserName"
                              onClick={() =>
                                navigate(`/article-details/${data?._id}`, {
                                  state: {
                                    flag: "article",
                                  },
                                })
                              }
                            >
                              <h4 className="tableSubText">
                                {data?.articleId}
                              </h4>
                            </td>
                            <td className="recentSubHead">
                              <h4 className="tableSubText">{data?.title}</h4>
                            </td>
                            <td className="recentSubHead table_UserName">
                              <div className="publishInfo">
                                {data?.publications?.length === 1 ? (
                                  <>
                                    <div
                                      className="publishInfo"
                                      onClick={() =>
                                        navigate("/publicationsDetails", {
                                          state: {
                                            publicationId:
                                              data?.publications[0]
                                                ?.publicationId,
                                          },
                                        })
                                      }
                                    >
                                      <figure className="publishOutLogo">
                                        <img
                                          src={
                                            data?.publications[0]
                                              ?.publicationLogo
                                          }
                                          alt="publication Logo"
                                          className="img-fluid publishImg"
                                        />
                                      </figure>
                                      <h4 className="tableSubText">
                                        {data?.publications[0]
                                          ?.publicationName || "---"}
                                      </h4>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <figure className="publishOutLogo">
                                      <img
                                        src={
                                          data?.publications[0]?.publicationLogo
                                        }
                                        alt="publication Logo"
                                        className="img-fluid publishImg"
                                      />
                                    </figure>
                                    <p className="tableSubText">
                                      {`${data?.publications[0]?.publicationName}`}
                                      &nbsp;&nbsp;
                                      <span
                                        onClick={() => {
                                          handleOpenModal(
                                            "publicationGenreModal",
                                            data?.publications
                                          );
                                        }}
                                        className="view-all-genres pointCursor"
                                      >
                                        +{data?.publications?.length - 1}
                                      </span>
                                    </p>
                                  </>
                                )}
                              </div>
                            </td>
                            <td className="recentSubHead">
                              <h4 className="tableSubText">
                                {moment(data?.createdAt).format("MMM-DD-YYYY")}
                              </h4>
                            </td>

                            {/* <td className="recentSubHead">
                              <h4 className="tableSubText DescHead">
                                <span className="DescTicket">
                                  
                                  { extractedText.trim() === "" ? "-----------" :markDowntoText(
                                    extractedText?.length <= 90
                                      ? extractedText
                                      : `${extractedText?.slice(0, 90)}...`
                                  )}
                                </span>
                              </h4>
                            </td> */}
                            {/* <td className="recentSubHead table_UserName" onClick={() =>
                          navigate(`/user-detail/${data?.userId}`, {
                            state: {
                              flag: "article",
                            },
                          })}>
                              <div className="nameProfile">
                                <div className="profile-outer">
                                  <img
                                    src={
                                      data?.Users?.profilePhoto
                                        ? data?.Users?.profilePhoto
                                        : images.dummyProfile
                                    }
                                    className="img-fluid profileImg_"
                                    alt="profileImg"
                                  />
                                </div>
                                <h4 className="tableSubText">
                                  {`${data?.Users?.firstName
                                    ? data?.Users?.firstName
                                    : ""
                                    } ${data?.Users?.lastName
                                      ? data?.Users?.lastName
                                      : ""
                                    }`}
                                </h4>
                              </div>
                            </td> */}
                            <td className="recentSubHead ">
                              <h4 className="tableSubText">
                                <button
                                  type="button"
                                  title={
                                    data?.submittedForPublication && !data?.published
                                      ? "Submitted for Publication"
                                      : ""
                                  }
                                  className={
                                    data?.status == "under_review"
                                      ? "pendingTkt"
                                      : data?.status == "rejected"
                                      ? "rejectedTkt"
                                      : "completeTkt"
                                  }
                                >
                                  {data?.status == "under_review"
                                    ? "PENDING"
                                    : data?.submittedForPublication &&
                                      !data?.published
                                    ? "Submitted..."
                                    : data?.submittedForPublication &&
                                      data?.published
                                    ? "Published"
                                    : data?.status}
                                </button>
                              </h4>
                            </td>
                            <td className="recentSubHead text-center">
                              <button
                                className="editBtn view_Btn"
                                type="button"
                                onClick={() =>
                                  navigate(`/article-details/${data?._id}`, {
                                    state: {
                                      flag: "article",
                                    },
                                  })
                                }
                              >
                                <img
                                  src={images.eyeImg}
                                  className="img-fluid"
                                  alt="editPen image"
                                />
                                View
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8} className="noFoundHead">
                          <h3 className="text-center subhead24 m-0">
                            No Articles Found!
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}
            </table>

            <CustomModal
              key={key}
              show={modalDetail.show}
              backdrop="static"
              showCloseBtn={false}
              isRightSideModal={true}
              mediumWidth={false}
              className={
                modalDetail.flag === "createcategory" ||
                modalDetail.flag === "updateCategoryModal"
                  ? "commonWidth customContent"
                  : ""
              }
              ids={
                modalDetail.flag === "deletePublish"
                  ? "deletCategoryModal"
                  : modalDetail.flag === "publicationGenreModal"
                  ? "deletCategoryModal"
                  : ""
              }
              child={
                modalDetail.flag === "publicationGenreModal" ? (
                  <PublicationGenreModal
                    allCategoryNames={allPublicationNames}
                    close={() => handleOnCloseModal()}
                    flag="Publication"
                  />
                ) : (
                  ""
                )
              }
              header={
                modalDetail.flag === "publicationGenreModal" ? (
                  <>
                    <h2 className="modal_Heading">Publications </h2>
                    <p onClick={handleOnCloseModal} className="modal_cancel">
                      <img
                        src={images.modalCross}
                        className="ModalCance"
                        alt=""
                      />
                    </p>
                  </>
                ) : (
                  ""
                )
              }
              onCloseModal={() => handleOnCloseModal()}
            />
          </div>
          {articleList && articleList?.length > 0 && (
            <div className=" paginateSec">
              <ReactPaginate
                className="paginationBox mb-0"
                nextLabel=" >"
                onPageChange={handlePageChange}
                curr
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< "
                renderOnZeroPageCount={null}
                forcePage={currentPage - 1}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Article;
