import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
  notificationData: {},
  success: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
    getNotification: (state) => {
      state.loading = true;
    },
    setGetNotification: (state, action) => {
      state.loading = false;
      state.notificationData = action.payload;
    },
    updateNotification: (state) => {
      state.loading = false;
      // state.success = false;
    },
    setUpdateNotification: (state) => {
      state.loading = false;
      // state.success = true;
    },
    readNotification: (state) => {
      state.loading = true;
      state.success = false;
    },
    setReadNotification: (state) => {
      state.loading = false;
      state.success = true;
    },
    sendMessageNotification: (state) => {
      state.loading = true;
    },
    setsendMessageNotification: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onErrorStopLoad,
  getNotification,
  setGetNotification,
  updateNotification,
  setUpdateNotification,
  readNotification,
  setReadNotification,
  sendMessageNotification,
  setsendMessageNotification
} = notificationSlice.actions;
