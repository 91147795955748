import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    onErrorStopLoad: (state) => {
        state.loading = false;
      },
      getTransaction: (state) => {
        state.loading = true;
      },
      setGetTransaction: (state, action) => {
        state.loading = false;
      },
      getArticleTransaction: (state) => {
        state.loading = true;
      },
      setArticleTransaction: (state, action) => {
        state.loading = false;
      },
    },
  });
  
  // Action creators are generated for each case reducer function
  export const {
    onErrorStopLoad,
    getTransaction,
    setGetTransaction,
    getArticleTransaction,
    setArticleTransaction,
  } = transactionSlice.actions;
  
  export default transactionSlice.reducer;
  