import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { onErrorStopLoad, updateStatus } from "../../redux/slices/dashboard";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";

const DeactivateUserModal = (props) => {
  const { close, deactivateId, status, getOneUserDetail } = props;
  const dispatch = useDispatch();
  const userActivateDeactivate = UseDashBoardSelector();
  const { loading } = userActivateDeactivate;

  // delete helper page
  const handleDeActivateUser = () => {
    let params = {
      _id: deactivateId,
      status: status === "Active" ? "Inactive" : "",
    };
    dispatch(
      updateStatus({
        ...params,
        cb(res) {
          if (res?.data) {
            getOneUserDetail();
            close();
          }
        },
      })
    );
  };

  // stop loading
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="deleteModalsec text-center my-3">
        <button
          className="blockbtn btnYellow w-100"
          onClick={handleDeActivateUser}
        >
          Yes
          {loading && (
            <span className="spinner-border spinner-border-sm ms-1"></span>
          )}
        </button>
      </div>
    </>
  );
};

export default DeactivateUserModal;
