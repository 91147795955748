const ApiPath = {
  AuthApiPath: {
    ADMIN_LOGIN: "/users/login",
    FORGOT_PASSWORD: "/users/forgot-password",
    RESET_PASSWORD: "/users/reset-password",
    LOGOUT_ADMIN: "/users/logOut",
    ENTER_OTP: "/users/reset-password-otp-verify",
    RESEND_ENTER_OTP: "/users/resend",
    ADMIN_UPDATE_PROFILE: "/users",
    ADMIN_CHANGE_PASSWORD: "/users/change-password",
    ADMIN_UPLOAD_PROFILE_PHOTO: "/users/upload-profile-image",
    ACCESS_TOKEN: "/users/access-token",
  },
  dashboardApiPath: {
    DASHBOARD_USERS: "/users/dashboard",
    USERS_LIST: "/users",
    USER_DETAIL: "/users",
    DELETE_USER: "/users",
    UPDATE_STATUS: "/users/update-status",
    ARTICLES_CATEGORIES: "/categories",
    DELETE_CATEGORY: "/categories",
    CREATE_CATEGORY: "/categories",
    CREATE_SUB_CATEGORY: "/categories/add-sub-category",
    GET_ONECATEGORY: "/categories",
    UPDATE_ONECATEGORY: "/categories",
    CHECK_EXIST_CATEGORY: "/categories/check-by-name",
    GET_ALL_PUBLICATIONS: "/publications",
    DELETE_PUBLICATION: "/publications",
    ADD_PUBLICATION: "/publications",
    UPLOAD_LOGO_PUBLICATION: "/publications/upload-profile-image",
    EDIT_PUBLICATION: "/publications",
    GET_SUBSCRIPTION: "/plans/get-plans",
    GET_CHANGE_SUBSCRIPTION: "/plans",
    CREATE_SUBSCRIPTION: "/plans",
    DELETE_SUBSCRIPTION: "/plans",
    UPDATE_SUBSCRIPTION: "/plans",
    CHANGE_USER_PLAN: "subscriptions/user-subscription",
    GET_CATEGORIES: "/categories",
    GET_ALL_REGIONS: "/publications/regions",
  },
  ticketApiPath: {
    TICKET_LIST: "/tickets",
    UPDATE_TICKET_STATUS: "/tickets/status",
    UPLOAD_TICKET_IMAGE: "/tickets/upload",
  },
  helperApiPath: {
    HELPER_LIST: "/helperpages",
  },
  articleApiPath: {
    ARTICLE_LIST: "/articles",
    UPDATE_ARTICLE_STATUS: "/articles/status",
    SUBMIT_PUBLICATION_ARTICLE: "/articles/submit-publication",
  },
  notificationApiPath: {
    ARTICLE_NOTIFICATION: "/notifications",
    UPDATE_NOTIFICATION: "/users/update-notification",
    READ_NOTIFICATION: "/notifications",
  },
  socialLinkApiPath: {
    GET_ALL_SOCAIL_LINK: "/socialMedia/",
    GET_ONE_SOCAIL_LINK: "/socialMedia",
    GET_UPDATE_SOCAIL_LINK: "/socialMedia",
    ACTIVE_INACTIVE_LINK: "/socialMedia/toggle",
    GET_CLIENT_LOGO: "/clientLogos/",
    UPLOAD_CLIENT_LOGO: "/clientLogos/upload-logo",
    ADD_CLIENT_LOGO: "/clientLogos",
    EDIT_CLIENT_LOGO: "/clientLogos",
  },
  transactionApiPath: {
    GET_TRANSACTION: "/subscriptions/transactions",
    GET_ARTICLE_TRANSACTION: "/articles/article-transactions",
  },
};

export default ApiPath;
