import { useLocation, useNavigate } from "react-router-dom";
import * as images from "../../utilities/images";
import {
  getAllPublications,
  getOnePublications,
} from "../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";
// import { hand } from "../../utilities/images";
const PublicationsDetails = () => {
  const dashBoardSelector = UseDashBoardSelector();
  const { loading } = dashBoardSelector;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  console.log(location, "1111111");
  const [getPublication, setGetPublication] = useState("");
  console.log(getPublication, "getPublication");

  const getPublications = () => {
    let params = {
      id: state?.publicationId,
    };

    dispatch(
      getOnePublications({
        ...params,
        cb(res) {
          if (res?.data) {
            setGetPublication(res?.data?.data);
          }
        },
      })
    );
  };

  useEffect(() => {
    getPublications();
  }, []);

  return (
    <>
      <div className="content-wrap categories publicationsec">
        {loading ? (
          <>
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          </>
        ) : (
          <>
            <div className="innerHeadFlex">
              <div className="userDeatilHead d-inline-flex align-items-center ">
                <img
                  src={images.arrowMain}
                  alt="arrowMainimg"
                  className="img-fluid pointCursor"
                  onClick={() => navigate(-1)}
                />
                <h1 className="mainHead32">Publications Details</h1>
              </div>

              <button
            className="loginBtnCommon btnYellow add_btn m-0"
            onClick={() => {
              navigate("/editPublications",{
                state:{publishList:getPublication,
                  type: "editPublish",
                }
              });
            }}
          >
            Edit
          </button>
            </div>
            <div className="row">
              <div className="col-xl-4 mb-4">
                <div className="publicationImg_Parent">
                  <img
                    src={getPublication?.publicationLogo}
                    alt="handImg"
                    className="publication_Img"
                  />
                </div>
              </div>
              <div className="col-xl-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="pubDetails_firstContainer">
                      <div className="pubDetails_Container ">
                        <span className="pubDetails_Head fontSize18">
                          Genre Name:
                        </span>
                        {getPublication?.categoryIds?.map((genre, index) => (
                          <span
                            key={index}
                            className="fontSize18 secondary_Heading"
                          >
                            {`${index > 0 ? "," : ""} ${genre?.categoryName}`}
                          </span>
                        ))}
                      </div>

                      <div className="pubDetails_Container  ">
                        <span className="pubDetails_Head fontSize18">
                          Type Name:
                        </span>
                        <span className="fontSize18 secondary_Heading">
                          {getPublication?.subCategoryName || "-----"}
                        </span>
                      </div>

                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Publication Name:
                        </span>
                        <span className="fontSize18 secondary_Heading">
                          {getPublication?.name}
                        </span>
                      </div>

                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">DA:</span>
                        <span className="fontSize18 secondary_Heading">
                          {getPublication?.da}
                        </span>
                      </div>

                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Region:
                        </span>
                        <span className="fontSize18 secondary_Heading">
                          {getPublication?.region}
                        </span>
                      </div>
                      {getPublication?.publicationType === "premium" && (
                      <div className="pubDetails_Container">
                            <span className="pubDetails_Head fontSize18">
                              MarkUp:
                            </span>
                            <span className="fontSize18 secondary_Heading">
                              {getPublication?.markup}
                            </span>
                          </div>)}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="pubDetails_secondContainer">
                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Sponsored:
                        </span>
                        <span className="fontSize18 text-capitalize secondary_Heading">
                          {getPublication?.sponsored}
                        </span>
                      </div>
                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Indexed:
                        </span>
                        <span className="fontSize18 text-capitalize secondary_Heading">
                          {getPublication?.indexed}
                        </span>
                      </div>
                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Rating:
                        </span>
                        <span className="fontSize18 secondary_Heading">
                          {getPublication?.rating}
                        </span>
                      </div>
                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Do Follow:
                        </span>
                        <span className="fontSize18 text-capitalize secondary_Heading">
                          {getPublication?.doFollow}
                        </span>
                      </div>

                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Website URL:
                        </span>
                        <span className="fontSize18  webUrl_Txt secondary_Heading">
                          <a
                            href={getPublication?.websiteUrl}
                            target="_blank"
                            className="pointCursor fontSize18"
                          >
                            {getPublication?.websiteUrl
                              ? getPublication?.websiteUrl?.length <= 20
                                ? getPublication?.websiteUrl
                                : `${getPublication?.websiteUrl?.slice(
                                    0,
                                    20
                                  )}...`
                              : "---"}
                          </a>
                        </span>
                      </div>

                      {getPublication?.publicationType === "premium" && (
                        <>
                          <div className="pubDetails_Container">
                            <span className="pubDetails_Head fontSize18">
                              Price:
                            </span>
                            <span className="fontSize18 secondary_Heading">
                              ${getPublication?.amount}
                            </span>
                          </div>
                          
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="pubDetails_thirdContainer">
                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Time:
                        </span>
                        <span className="fontSize18 secondary_Heading">
                          {getPublication?.selectTime}
                        </span>
                      </div>
                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Niche:
                        </span>
                        <span className="fontSize18 text-capitalize secondary_Heading">
                          {getPublication?.niche}
                        </span>
                      </div>
                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Image:
                        </span>
                        <span className="fontSize18 text-capitalize secondary_Heading">
                          {getPublication?.image}
                        </span>
                      </div>
                      <div className="pubDetails_Container">
                        <span className="pubDetails_Head fontSize18">
                          Publication Type:
                        </span>
                        <span className="fontSize18 text-capitalize secondary_Heading">
                          {getPublication?.publicationType}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default PublicationsDetails;
