import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import { onErrorStopLoad, setGetNotification, setReadNotification, setsendMessageNotification, setUpdateNotification } from "../../slices/notification";


function* getNotification(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.notificationApiPath.ARTICLE_NOTIFICATION), action.payload);
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setGetNotification(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}
function* updateNotification(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.notificationApiPath.UPDATE_NOTIFICATION),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setUpdateNotification(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* readNotification(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.notificationApiPath.READ_NOTIFICATION),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setReadNotification(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* sendMessageNotification(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.notificationApiPath.ARTICLE_NOTIFICATION),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setsendMessageNotification(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* notificationSaga() {
  yield all([takeLatest("notification/getNotification", getNotification)]);
  yield all([takeLatest("notification/updateNotification", updateNotification)]);
  yield all([takeLatest("notification/readNotification", readNotification)]);
  yield all([takeLatest("notification/sendMessageNotification", sendMessageNotification)]);
}

export default notificationSaga;
