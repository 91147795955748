import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuthSelector } from "../../redux/selector/auth";
import { useNotificateSelector } from "../../redux/selector/notification";
import { userDetail } from "../../redux/slices/dashboard";
import {
  readNotification,
  updateNotification,
} from "../../redux/slices/notification";
import * as Images from "../../utilities/images";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  document.title = "Notifications";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useAuthSelector();
  const notificationSelector = useNotificateSelector();
  const [notificationFlag, setNotificationFlag] = useState("");
  const { loading } = notificationSelector;
  const [notificationId, setNotiFicationId] = useState("");
  const [enableDisabled, setEnableDisabled] = useState("");
  /**
   * notifications mark as read
   * @param {*} id
   * @param {*} read
   */
  const readNotifyMsg = (id, notificationId,userdelete) => {
    setNotiFicationId(id);
    let params = {
      is_read: "true",
      id: id,
    };
    dispatch(
      readNotification({
        ...params,
        cb(res) {
          if (res?.data) {
            if (userdelete) {
              navigate("/user-delete");
            }
            else{
            if (res?.data?.data?.type === "article_posted")
              navigate(`/article-details/${notificationId}`);
            if(res?.data?.data?.type === "articleMessage")
              navigate(`/article-details/${notificationId}`,{
                state:{
                  flag:"articleMessage"
                }});
                if(res?.data?.data?.type === "ticketMessage")
                  navigate(`/ticket-details/${notificationId}`,{
                    state:{
                      flag:"ticketMessage"
                    }});
            if (
              res?.data?.data?.type === "subscription_created" ||
              res?.data?.data?.type === "User_created"||
              res?.data?.data?.type === "subscription_canceled"
            )
              navigate(`/user-detail/${notificationId}`, {
                state: {
                  flag: "notification",
                },
              });
            if (res?.data?.data?.type === "ticket_created")
              navigate(`/ticket-details/${notificationId}`, {
              });
          }}
        },
      })
    );
  };

  /**
   * get user details
   */
  const getOneUserDetail = () => {
    let params = {
      id: authSelector?.loginData?.userInfo?.id,
    };
    dispatch(
      userDetail({
        ...params,
        cb(res) {
          if (res?.data) {
            setNotificationFlag(res?.data?.data?.userInfo?.pushNotification);
            setEnableDisabled(res?.data?.data?.userInfo?.pushNotification);
          }
        },
      })
    );
  };

  /**
   * enable notificaition
   * @param {*} flag
   */

  const enableNotification = (flag) => {
    let params = {
      pushNotification: flag,
    };
    dispatch(
      updateNotification({
        ...params,
        cb(res) {
          if (res?.status) {
            getOneUserDetail();
          }
        },
      })
    );
  };

  useEffect(() => {
    getOneUserDetail();
  }, []);

  return (
    <>
      <div className="content-wrap notificateSec">
        <div className="innerHeadFlex mb-5">
          <h1 className="mainHead32">Notifications</h1>
          <div className="notificationBtn">
            <h4 className="labelText">Push notifications:</h4>
            <div className="enableBox">
              <div class="roundCheck  mb-0">
                <label
                  className="form-check-label userDarkPara me-2"
                  for="flexSwitchCheckChecked"
                >
                  {enableDisabled ? "Enabled" : "Disabled"}
                </label>
                <input
                  checked={notificationFlag}
                  class="form-check-input m-0"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  onChange={(e) => {
                    enableNotification(e.target.checked);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* {loading && !notificationId ? (
          <>
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          </>
        ) : (
          <> */}
            {notificationSelector?.notificationData?.length > 0 ? (
              notificationSelector?.notificationData?.map((value, index) => (
                <>
                  {loading && value?._id === notificationId ? (
                    <div className="notifyList notifyLoader">
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        value?.is_read === false
                          ? "notifyList pointCursor active"
                          : "notifyList pointCursor"
                      }
                      key={index}
                      onClick={() => {
                        readNotifyMsg(
                          value?._id,
                          value?.notificationTypeId,
                          value?.user?.deletedAt
                        );
                      }}
                    >
                      <div className="notifyMsgArea">
                        <figure className="notifyUser">
                          <img
                            src={
                              value?.user?.profilePhoto || Images.dummyProfile
                            }
                            alt="publication Logo"
                            className="img-fluid notifyUserImg"
                          />
                          <div className="bellNotify">
                            <img
                              src={Images.lightBell}
                              alt="publication Logo"
                              className="img-fluid"
                            />
                          </div>
                        </figure>
                        <div className="notifyData">
                          <p className="userDarkPara mb-1">
                            {value?.description}
                          </p>
                          <h6 className="notifyTime">
                            {moment(value?.createdAt).fromNow()}
                          </h6>
                        </div>
                      </div>
                      <span className="unreadBtn"></span>
                    </div>
                  )}
                </>
              ))
            ) : (
              <h3 className="text-center m-0 mainHead32 noNotificationsFound">
                No Notification Found!
              </h3>
            )}
          {/* </>
        )} */}
      </div>
    </>
  );
};

export default Notification;
