import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Showdown from "showdown";
import { useArticleSelector } from "../../redux/selector/article";
import {
  getOneArticle,
  submitPublicationArticle,
  updateOneArticle,
} from "../../redux/slices/article";
import * as images from "../../utilities/images";
import ArticleMessageModal from "../Modal/articleMessageModal";
import CustomModal from "../Modal/CustomModal";
import ArticleMessage from "./ArticleMessage";
import RejectionReasonModal from "./RejectionReasonModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Multiselect } from "multiselect-react-dropdown";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const ArticleDetails = () => {
  const scrollChatBox = useRef(null);
  const articleSelector = useArticleSelector();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loading } = articleSelector;
  const [articleDetails, setArticleDetails] = useState("");
  console.log(articleDetails, "loadingarticleDetails");

  const [isShowMessageBox, setIsShowMessageBox] = useState(
    location?.state?.flag === "articleMessage" || false
  );
  const [key, setKey] = useState(Math.random());
  const [htmlContent, setHtmlContent] = useState("");
  const [changesStatus, setChangesStatus] = useState("");
  const [dropdownValue, setDropdownValue] = useState({});
  console.log(articleDetails, "changesStatus");
  const [articlesTotalAmount, setArticlesTotalAmount] = useState("");
console.log(articlesTotalAmount,"articlesTotalAmount");

  const converter = new Showdown.Converter();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const convertMarkdownToHtml = (text) => {
    const convertedHtml = converter.makeHtml(text);
    setHtmlContent(convertedHtml);
  };

  useEffect(() => {
    if (articleDetails?.description) {
      convertMarkdownToHtml(articleDetails?.description);
    }
  }, [articleDetails?.description]);

  /**
   * modal close function
   */

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  /**
   * Article details api calling
   */

  const getOneArticleDetail = () => {
    let params = {
      id: id,
    };
    dispatch(
      getOneArticle({
        ...params,
        cb(res) {
          if (res?.data) {
            setArticleDetails(res?.data?.data);
            const sumPublicationAmounts = res?.data?.data?.publications?.reduce(
              (sum, item) => {
                const publicationAmount = item.publicationPrice
                  ? parseInt(item.publicationPrice)
                  : 0;
                const amount = item.amount || 0;
                return sum + publicationAmount + amount;
              },
              0
            );

            setArticlesTotalAmount(sumPublicationAmounts);
            setChangesStatus(
              res?.data?.data?.published
                ? "publish"
                : res?.data?.data?.submittedForPublication
                ? "submittedPublication"
                : ""
            );
            setDropdownValue({
              submittedForPublication: res?.data?.data?.submittedForPublication,
              published: res?.data?.data?.published,
            });
          }
        },
      })
    );
  };

  /**
   * status change api function
   * @param {*} status
   * @param {*} rejectReason
   */

  const handleStatusChange = (status, rejectReason) => {
    let params = {
      id: id,
      userId: articleDetails?.userId,
      status: status,
      rejectedReason: rejectReason || undefined,
    };
    dispatch(
      updateOneArticle({
        ...params,
        cb(res) {
          if (res?.data) {
            getOneArticleDetail();
            handleOnCloseModal();
          }
        },
      })
    );
  };

  const handlesubmitforPublication = (value, flag) => {
    console.log(value, "valuevalue");
    let params = {
      id: id,
    };

    if (value === "submittedPublication") {
      params = {
        ...params,
        submittedForPublication: true,
      };
    }

    if (value === "publish") {
      params = {
        ...params,
        publish: true,
      };
    }
    dispatch(
      submitPublicationArticle({
        ...params,
        cb(res) {
          if (res?.data) {
            getOneArticleDetail();
            setChangesStatus(value);
          }
        },
      })
    );
  };

  const handleNavigation = () => {
    if (location.state?.flag === "userDetails") {
      navigate(`/user-detail/${articleDetails?.userId}`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    getOneArticleDetail();
  }, [id]);

  useEffect(() => {
    if (scrollChatBox?.current && isShowMessageBox) {
      scrollChatBox?.current?.scrollIntoView({
        block: "end",
        inline: "end",
        behavior: "smooth",
      });
    }
  }, [isShowMessageBox]);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-right"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginRight: "10px 11px",
          }}
        ></i>
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-left"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginLeft: "10px 11px",
          }}
        ></i>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,

    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <>
      <section className="content-wrap dashboardSection articleDetail_Section">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <Link to="/article"> */}
                <div className="userDeatilHead d-inline-flex align-items-center ">
                  <img
                    src={images.arrowMain}
                    alt="arrowMainimg"
                    className="img-fluid pointCursor"
                    onClick={handleNavigation}
                  />
                  <h1 className="mainHead32">
                    Article: #{articleDetails?.articleId}
                  </h1>
                </div>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="loaderOuter d-flex justify-content-center text-light">
            <div className="spinner-grow text-light" role="status"></div>
          </div>
        ) : (
          <>
            <div className="dashArticle pt-0">
              <div className="articleDetail_Fixed">
                <div className="innerHead">
                  <div className="innerHeadleft">
                    <h2 className="heading_22">{articleDetails?.title}</h2>
                  </div>
                  <div className="headRightbox_">
                    <h4 className="categoryText fw-400">
                      {moment(articleDetails?.createdAt).format("MM/DD/YYYY")}
                    </h4>
                    {/* {(articleDetails?.status !== "approved"||articleDetails?.status !== "submittedForPublication"||articleDetails?.status !== "published") && (
                      <button
                        type="button"
                        className={
                          articleDetails?.status === "under_review"
                            ? "pendingTkt"
                            : articleDetails?.status === "approved"
                            ? "completeTkt"
                            : articleDetails?.status === "rejected"
                            ? "rejectedTkt"
                            : "completeTkt"
                        }
                      >
                        {articleDetails?.status === "under_review"
                          ? "Pending"
                          : articleDetails?.status}
                      </button>
                    )} */}

                    {articleDetails?.status === "approved" ||
                    articleDetails?.status === "submittedForPublication" ||
                    articleDetails?.status === "published" ? (
                      <FormControl>
                        <Select
                          // className="customSelect"
                          className={
                            dropdownValue.published
                              ? "customSelect articlePublish_Btn"
                              : "customSelect articlePublish_Btn publishedBtn "
                          }
                          value={changesStatus || ""}
                          onChange={(e) => {
                            handlesubmitforPublication(e.target.value);
                          }}
                          label="Category"
                          displayEmpty
                          disabled={dropdownValue.published}
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            fontFamily: "Poppins",
                            ".MuiSvgIcon-root": {
                              color: "#fff", // Change this to the desired color
                              // display: `${dropdownValue.published  ? "none" : "block"}`,
                            },
                            fontStyle: "normal",
                          }}
                          renderValue={
                            changesStatus === ""
                              ? () => "Approved"
                              : changesStatus === "submittedPublication"
                              ? () => "Submitted for Publication"
                              : undefined
                          }
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                borderRadius: "12px",
                                border: "1px solid #FFFFFF4D",
                                background: "#616161",
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                marginTop: "5px",
                                " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                                  {
                                    color: "#ffffff",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    fontFamily: "inherit",
                                    marginTop: "3px",
                                    "&.Mui-selected": {
                                      backgroundColor: "#757575",
                                    },
                                    "&:hover": {
                                      backgroundColor: "#757575",
                                    },
                                    "&.Mui-selected:hover": {
                                      backgroundColor: "#757575",
                                    },
                                  },
                              },
                            },
                          }}
                        >
                          {/* <MenuItem value="" disabled>
                          Approved
                          </MenuItem> */}

                          {changesStatus !== "submittedPublication" && (
                            <MenuItem value="submittedPublication">
                              Submitted for Publication
                            </MenuItem>
                          )}
                          <MenuItem value="publish">Published</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <button
                        type="button"
                        className={
                          articleDetails?.status === "under_review"
                            ? "pendingTkt"
                            : articleDetails?.status === "approved"
                            ? "completeTkt"
                            : articleDetails?.status === "rejected"
                            ? "rejectedTkt"
                            : "completeTkt"
                        }
                      >
                        {articleDetails?.status === "under_review"
                          ? "Pending"
                          : articleDetails?.status}
                      </button>
                    )}
                    {/* {articleDetails?.status === "approved" && (
                      <button
                        type="button"
                        className={
                          !articleDetails?.submittedForPublication
                            ? "pendingTkt"
                            : articleDetails?.submittedForPublication
                            ? "completeTkt"
                            : ""
                        }
                        onClick={() => handlesubmitforPublication()}
                      >
                        {!articleDetails?.submittedForPublication
                          ? "submitted for publication"
                          : articleDetails?.submittedForPublication
                          ? "Published"
                          : ""}
                      </button>
                    )} */}
                  </div>
                </div>
                {/* <div className="articleSubcategory_ mt-3">
                <h4 className="categoryText fw-400">
                  Article Genre:{" "}
                  <strong className="textWhite fw-500">
                    {" "}
                    {articleDetails?.categoryName}
                  </strong>
                </h4>
                <h4 className="categoryText fw-400">
                  Type:{" "}
                  <strong className="textWhite fw-500">
                    {" "}
                    {articleDetails?.subCategoryName}
                  </strong>
                </h4>
              </div> */}

                <div className="articlePublication_ mt-3">
                  <h5 className="heading_22">Publication:</h5>
                  <div className="publicationMedia_">
                    {articleDetails?.publications?.map((value, idx) => (
                      <div
                        className="mediaInner_ pointCursor"
                        onClick={() =>
                          navigate("/publicationsDetails", {
                            state: { publicationId: value?.publicationId },
                          })
                        }
                      >
                        <img
                          src={value?.publicationLogo}
                          className="img-fluid me-2 articlePublish"
                          alt="media_image"
                        />
                        <span className="form_subheading">
                          {value?.publicationName}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                {articleDetails?.url && (
                  <div className="articlePublication_ article_Publication_Resp mt-3">
                    <h5 className="heading_22">Url:</h5>
                    <div className="mediaInner_ d-flex align-items-center">
                      {/* <Link target="_blank" > */}
                      <a
                        href={articleDetails?.url}
                        target="_blank"
                        className="form_subheading"
                      >
                        {articleDetails?.url}
                      </a>
                      {/* </Link> */}
                    </div>
                  </div>
                )}
              </div>

              <div className="articleImage text-center">
                {articleDetails?.photos?.length > 0 && (
                  <img
                    src={articleDetails?.photos[0]}
                    className="img-fluid articleCover_Img"
                    alt="media_image"
                  />
                )}
                {articleDetails?.creditImage && (
                  <>
                    <p className="form_subheading mt-2 text-center">
                      {articleDetails?.creditImage}
                    </p>
                  </>
                )}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="textWhite mt-3 underimage_">
                    <div
                      className="app "
                      dangerouslySetInnerHTML={{
                        __html: htmlContent,
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="row">
                {articleDetails?.multipleImages?.length > 0 &&
                  articleDetails?.multipleImages?.map((item) => (
                    <div className="col-md-4">
                      <div className="mt-3">
                        <img
                          src={item}
                          className="img-fluid articleCover_Img articleMultiple_Img"
                          alt="media_image"
                        />
                      </div>
                    </div>
                  ))}
                   </div> */}
              <div className="row mt-4 articleSlider_Row">
                <div className="slider-container">
                  {articleDetails?.multipleImages?.length > 1 ? (
                    <Slider {...settings}>
                      {articleDetails?.multipleImages?.map((item, index) => (
                        <div className="p-2">
                          {console.log(item, "itemsssss")}
                          <a
                            key={index}
                            href={item}
                            className="marquee_Links"
                            target="_blank"
                          >
                            <div className="client_Logoes">
                              <img
                                src={item}
                                alt="slider_img"
                                className="img-fluid w-100 h-100 home_slider_img"
                              />
                            </div>
                          </a>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <>
                      {articleDetails?.multipleImages?.map((item) => (
                        <div className="col-md-4">
                          <div className="mt-3">
                            <img
                              src={item}
                              className="img-fluid articleCover_Img"
                              alt="media_image"
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              {articleDetails?.googleDriveUrl && (
                <div className="articlePublication_ article_Publication_Resp mt-3">
                  <h5 className="heading_22">Google Drive Url :-</h5>
                  <div className="mediaInner_ d-flex align-items-center">
                    <a
                      className="form_subheading"
                      href={articleDetails?.googleDriveUrl}
                      target="_blank"
                    >
                      {articleDetails?.googleDriveUrl}
                    </a>
                  </div>
                </div>
              )}

              {/* New */}
              {articleDetails?.articleType === "ai" && (
                <>
                  {articleDetails?.articleBy !== "company" && (
                    <>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Basic Information
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Full Name:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData?.basicInfo
                                      ?.fullName
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Professional Title:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData?.basicInfo
                                      ?.professionalTitle
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Industry/Field:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData?.basicInfo
                                      ?.industry
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Website:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData?.basicInfo
                                      ?.individualWebsite
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Personal Background Information
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                {" "}
                                Brief Overview:
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData
                                      ?.personalInfo?.bio
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                {" "}
                                Key Accomplishments:
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData
                                      ?.personalInfo?.accomplishment
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Personal Values or Philosophy:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData
                                      ?.personalInfo?.philosophy
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              {" "}
                              Professional Experience
                            </h3>
                            <ul>
                              {articleDetails?.individualsData
                                ?.professionalExperiennce?.previousRole && (
                                <li className="formDetail_List">
                                  {" "}
                                  Previous Notable Roles:
                                  <span className="ps-2">
                                    {
                                      articleDetails?.individualsData
                                        ?.professionalExperiennce?.previousRole
                                    }
                                  </span>
                                </li>
                              )}

                              <li className="formDetail_List">
                                Current Role/Position:
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData
                                      ?.professionalExperiennce?.currentPosition
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                {" "}
                                Major Projects/Contributions:
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData
                                      ?.professionalExperiennce?.projects
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Media or Social Proof
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Media Features/Recognition:
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData?.media
                                      ?.individualMediaFeature
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Social Media Links:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.individualsData?.media
                                      ?.individualSocialMedialinks
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {articleDetails?.individualsData?.quote && (
                          <div className="col-lg-6 ">
                            <div className="aiForm_Detail_Parent">
                              <h3 className="aiForm_Detail_Head">Quote</h3>
                              <ul>
                                <li className="formDetail_List">
                                  Personal Quote:
                                  <span className="ps-2">
                                    {articleDetails?.individualsData?.quote}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {articleDetails?.articleBy === "company" && (
                    <>
                      <div className="row ps-4 pe-4">
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Basic Information
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Brand/Company Name:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.basicInfo
                                      ?.companyName
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Industry/Field:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.basicInfo
                                      ?.industry
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Website:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.basicInfo
                                      ?.companyWebsite
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Company Overview
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Brief Overview:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData
                                      ?.companyOverview?.briefOverview
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Notable Achievements:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData
                                      ?.companyOverview?.achievements
                                  }
                                </span>
                              </li>
                              {articleDetails?.companiesData?.companyOverview
                                .foundHistory && (
                                <li className="formDetail_List">
                                  Founding Story:{" "}
                                  <span className="ps-2">
                                    {
                                      articleDetails?.companiesData
                                        ?.companyOverview.foundHistory
                                    }
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Products/Services
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Featured Products/Services:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.products
                                      ?.featuredProduct
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                NUnique Selling Points:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.products
                                      ?.sellingPoints
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Executive Leadership Quotes
                            </h3>
                            <ul>
                              {articleDetails?.companiesData?.quotes
                                ?.founderQuote && (
                                <li className="formDetail_List">
                                  CEO/Founder Quotes:{" "}
                                  <span className="ps-2">
                                    {
                                      articleDetails?.companiesData?.quotes
                                        ?.founderQuote
                                    }
                                  </span>
                                </li>
                              )}

                              <li className="formDetail_List">
                                Optional Customer/Partner Quotes:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.quotes
                                      ?.optionalQuote
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Media or Social Proof
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Previous Media Features:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.media
                                      ?.companyMediaFeatures
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Notable Partnerships:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.media
                                      ?.partnerhships
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Social Media Presence:{" "}
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.media
                                      ?.companySocialMedialinks
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Targeted Media Outlet/Industry Focus
                            </h3>
                            <ul>
                              {articleDetails?.companiesData?.focus
                                ?.preferredMedia && (
                                <li className="formDetail_List">
                                  Preferred Media Outlet:{" "}
                                  <span className="ps-2">
                                    {
                                      articleDetails?.companiesData?.focus
                                        ?.preferredMedia
                                    }
                                  </span>
                                </li>
                              )}

                              <li className="formDetail_List">
                                Geographic Focus:
                                <span className="ps-2">
                                  {
                                    articleDetails?.companiesData?.focus
                                      ?.geographic
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Common */}
                  <div className="row ps-4 pe-4">
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">Additional Notes</h3>
                        <ul>
                          <li className="formDetail_List">
                            Special Instructions:
                            <span className="ps-2">
                              {articleDetails?.additionalNote}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head"> Key Messages</h3>
                        <ul>
                          <li className="formDetail_List">
                            Main Message/Theme:
                            <span className="ps-2">
                              {articleDetails?.keyMessages?.mainMessage}
                            </span>
                          </li>
                          {articleDetails?.headline && (
                            <li className="formDetail_List">
                              Top 3 Highlights:
                              <span className="ps-2">
                                {articleDetails?.headline}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">Call to Action</h3>
                        <ul>
                          <li className="formDetail_List">
                            Desired Outcome:
                            <span className="ps-2">
                              {articleDetails?.callToAction?.desiredOutcomes}
                            </span>
                          </li>
                          <li className="formDetail_List">
                            Contact Information:
                            <span className="ps-2">
                              {articleDetails?.callToAction?.contactInfo}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">
                          Tone and Style Preferences
                        </h3>
                        <ul>
                          <li className="formDetail_List">
                            Preferred Tone:
                            <span className="ps-2">
                              {articleDetails?.Preferences?.tone}
                            </span>
                          </li>
                          <li className="formDetail_List">
                            Writing Style:
                            <span className="ps-2">
                              {articleDetails?.Preferences?.style}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">
                          Article Purpose/Goal
                        </h3>
                        <ul>
                          <li className="formDetail_List">
                            Purpose of the Article:
                            <span className="ps-2">
                              {articleDetails?.articlePurpose?.purpose}
                            </span>
                          </li>
                          <li className="formDetail_List">
                            Target Audience:
                            <span className="ps-2">
                              {articleDetails?.articlePurpose?.targetAudience}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">Headline/Title</h3>
                        <ul>
                          <li className="formDetail_List">
                            Suggested Headline:
                            <span className="ps-2">
                              {articleDetails?.keyMessages?.highlights}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-12 ">
                      {articleDetails?.attachments?.logo && (
                        <div className="aiForm_Detail_Parent">
                          <h3 className="aiForm_Detail_Head">Headline/Title</h3>

                          <ul>
                            <li className="formDetail_List mb-0">
                              Upload Image:
                            </li>
                          </ul>
                          <img
                            src={articleDetails?.photos[0]}
                            alt="preimage1"
                            className="aiFormUploadImg img-fluid"
                          />
                        </div>
                      )}
                      {articleDetails?.attachments?.files && (
                        <div className="aiForm_Detail_Parent">
                          <ul>
                            <li className="formDetail_List mb-0">
                              Additional Files:
                            </li>
                            <span className="ps-2">
                              <a
                                className="form_subheading pointCursor"
                                href={articleDetails?.attachments?.files}
                                target="_blank"
                              >
                                {articleDetails?.attachments?.files}
                              </a>
                            </span>
                          </ul>
                          <div className="docfile_Parent"></div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* End New */}


              {articleDetails?.reject?.[0] // Checks if reject array exists and has a truthy first element
                ? (articleDetails?.addOns?.totalAmount || 0) +
                  (articlesTotalAmount || 0) // Case when reject exists
                : (articlesTotalAmount || 0) +
                    (articleDetails?.addOns?.totalAmount || 0) > // Case when reject doesn't exist
                    0 && (
                    <>
                      <div className="addOnDetailSec">
                        <div className="offerDetailSec mt-5">
                          <h4 className="stateText">Offer Details</h4>
                          <div className="offerDetailBox mt-2">
                            <div className="offerUpper">
                              <h6 className="ratingText">Item</h6>
                              <h6 className="ratingText">Price</h6>
                            </div>
                            <div className="offerLower">
                              <h6 className="ratingText">
                                {articleDetails?.title}
                              </h6>
                              <h6 className="ratingText">
                                {articleDetails?.reject?.[0]
                                  ? "$" + `${articlesTotalAmount || 0} Paid`
                                  : "$" + articlesTotalAmount || 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="addOnDetailSec mt-5">
                        <div className="offerDetailSec mt-2">
                          <h4 className="stateText">Add-on Details</h4>
                          {articleDetails?.addOns?.professional ||
                          articleDetails?.addOns?.interviewer ? (
                            <div className="offerDetailBox mt-2">
                              <div className="offerUpper">
                                <h6 className="ratingText">Item</h6>
                                <h6 className="ratingText">Price</h6>
                              </div>
                              {articleDetails?.addOns?.professional && (
                                <div className="offerLower">
                                  <h6 className="ratingText">Professional</h6>
                                  <h6 className="ratingText">
                                    $
                                    {articleDetails?.reject?.[0]
                                      ? articleDetails?.addOns
                                          ?.professionalAmout
                                        ? `${
                                            articleDetails?.addOns
                                              ?.professionalAmout || 0
                                          } Paid`
                                        : articleDetails?.addOns
                                            ?.professionalAmout || 0
                                      : articleDetails?.addOns
                                          ?.professionalAmout || 0}
                                  </h6>
                                </div>
                              )}
                              <div className="offerLower addOnArticle_Checkbox mr-3 pt-0 justify-content-start">
                                {articleDetails?.addOns?.professional && (
                                  <>
                                    {articleDetails?.publications?.length > 0
                                      ? articleDetails.publications
                                          .filter(
                                            (item) =>
                                              item?.needProfessionalAssistant
                                          )
                                          .map((item, idx) => (
                                            <div className=" d-flex align-items-center ">
                                              <div class="form-group mb-0">
                                                <input
                                                  type="checkbox"
                                                  className="articleDetails_Checkbox"
                                                  id={idx}
                                                  checked={
                                                    item?.needProfessionalAssistant
                                                  }
                                                />
                                                <label
                                                  className="sub_p_text ms-2 mb-0"
                                                  key={item.id}
                                                  for="html"
                                                >
                                                  {item?.publicationName}
                                                </label>
                                              </div>
                                            </div>
                                          ))
                                      : ""}
                                  </>
                                )}
                              </div>

                              {articleDetails?.addOns?.interviewer && (
                                <div className="offerLower">
                                  <h6 className="ratingText">Interviewer</h6>
                                  <h6 className="ratingText">
                                    {/* ${articleDetails?.reject?.[0] ? `${articleDetails?.addOns?.interviewerAmount ||0} Paid` :articleDetails?.newAddOns?.interviewerAmount } */}
                                    $
                                    {articleDetails?.reject?.[0]
                                      ? articleDetails?.addOns
                                          ?.interviewerAmount
                                        ? `${
                                            articleDetails?.addOns
                                              ?.interviewerAmount || 0
                                          } Paid`
                                        : articleDetails?.addOns
                                            ?.interviewerAmount || 0
                                      : articleDetails?.addOns
                                          ?.interviewerAmount}
                                  </h6>
                                </div>
                              )}

                              <div className="offerLower addOnArticle_Checkbox mr-3 pt-0 justify-content-start">
                                {articleDetails?.addOns?.interviewer && (
                                  <>
                                    {articleDetails?.publications?.length > 0
                                      ? articleDetails.publications
                                          .filter(
                                            (item) =>
                                              item?.needInterviewerAssistant
                                          )
                                          .map((item, idx) => (
                                            <div className=" d-flex align-items-center ">
                                              <div class="form-group mb-0">
                                                <input
                                                  type="checkbox"
                                                  className="articleDetails_Checkbox"
                                                  id={idx}
                                                  checked={
                                                    item?.needInterviewerAssistant
                                                  }
                                                />
                                                <label
                                                  className="sub_p_text ms-2 mb-0"
                                                  key={item.id}
                                                  for="html"
                                                >
                                                  {item?.publicationName}
                                                </label>
                                              </div>
                                            </div>
                                          ))
                                      : ""}
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="totalBox">
                            <h6 className="ratingText">Add On Total</h6>
                            <h6 className="ratingText">
                              $
                              {articleDetails?.reject?.[0]
                                ? `${
                                    articleDetails?.addOns?.totalAmount || 0
                                  } Paid`
                                : articleDetails?.addOns?.totalAmount || 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="totalBox">
                        <h6 className="ratingText">Total Payment</h6>
                        <h6 className="ratingText">
                          $
                          {articleDetails?.reject?.[0]
                            ? `${
                                (articleDetails?.addOns?.totalAmount || 0) +
                                (articlesTotalAmount || 0)
                              } Paid`
                            : (articlesTotalAmount || 0) +
                              (articleDetails?.addOns?.totalAmount || 0)}
                        </h6>
                      </div>
                    </>
                  )}

              <div className="contentPreview_ mt-3">
                <h2 className="userNameText fw-500 text-capitalize">
                  Posted By:
                </h2>
                <div className="userDetailInfo">
                  <div
                    className="userDetailLeft pointCursor"
                    onClick={() =>
                      navigate(`/user-detail/${articleDetails?.userId}`)
                    }
                  >
                    <img
                      src={
                        articleDetails?.Users?.profilePhoto ||
                        images.profileMain
                      }
                      alt="arrowMainimg"
                      className="img-fluid profileMainImg"
                    />
                    <div className="userDeatilInner">
                      <h2 className="userNameText text-capitalize">
                        {`${articleDetails?.Users?.firstName || ""} ${
                          articleDetails?.Users?.lastName || ""
                        }`}
                      </h2>
                      <h6 className="userLabelText mt-3">
                        Email:{" "}
                        <span className="labelBold">
                          {articleDetails?.Users?.email}
                        </span>
                      </h6>
                      <h6 className="userLabelText mt-2">
                        Phone Number:{" "}
                        <span className="labelBold">
                          +{articleDetails?.Users?.dialcode}{" "}
                          {articleDetails?.Users?.phoneNo}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>

                {articleDetails?.status == "under_review" ? (
                  <>
                    <button
                      type="button"
                      className="blockbtn  me-3 mt-4"
                      disabled={loading}
                      onClick={() => {
                        handleStatusChange("approved");
                      }}
                    >
                      Approve
                      {loading && (
                        <span className="spinner-border spinner-border-sm ms-1"></span>
                      )}
                    </button>
                    <button
                      type="button"
                      className="secondaryBtb_ me-3 mt-4 "
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "rejectModal",
                        });
                        setKey(Math.random());
                      }}
                    >
                      Reject
                    </button>
                    {/* <button
                      type="button"
                      className="blockbtn mt-4"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "messageModal",
                        });
                        setKey(Math.random());
                      }}
                    >
                      message
                    </button> */}
                  </>
                ) : articleDetails?.status === "approved" ? (
                  ""
                ) : (
                  <button type="button" className="blockbtn me-3 mt-4" disabled>
                    {articleDetails?.status === "under_review"
                      ? "Pending"
                      : articleDetails?.status === "submittedForPublication"
                      ? "Submitted For Publication"
                      : articleDetails?.status}
                  </button>
                )}
                <button
                  type="button"
                  className="blockbtn mt-4"
                  onClick={() => {
                    setIsShowMessageBox(true);
                  }}
                >
                  message
                </button>
              </div>
            </div>
          </>
        )}
        <div
          ref={scrollChatBox}
          className={isShowMessageBox ? "d-block" : "d-none"}
        >
          <ArticleMessage articleDetails={articleDetails} />
        </div>
      </section>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createsubcategory" ||
          modalDetail.flag === "updateCategoryModal" ||
          modalDetail.flag === "messageModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "rejectModal" ||
          modalDetail.flag === "updateCategoryModal" ||
          modalDetail.flag === "messageModal"
            ? "subCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "rejectModal" ? (
            <RejectionReasonModal
              close={() => handleOnCloseModal()}
              handleStatusChange={(e, reject) => handleStatusChange(e, reject)}
              loading={loading}
            />
          ) : modalDetail.flag === "messageModal" ? (
            <div className="messageModalContainer">
              <ArticleMessageModal
                close={() => handleOnCloseModal()}
                articleDetails={articleDetails}
                // handleStatusChange={(e, reject) => handleStatusChange(e, reject)}
                loading={loading}
              />
            </div>
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "rejectModal" ? (
            <>
              <h2 className="modal_Heading">Rejection Reason</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCross}
                  className="ModalCancel img-fluid"
                  alt=""
                />
              </p>
            </>
          ) : modalDetail.flag === "messageModal" ? (
            <>
              <h2 className="modal_Heading">Messages</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCross}
                  className="ModalCancel img-fluid"
                  alt=""
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ArticleDetails;
