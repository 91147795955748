import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
// import { useSubscriptionSelector } from "../../../redux/selector/subscription";
// import {
//   getArticleTransaction,
//   getTransaction,
// } from "../../../redux/slices/subscription";
import { useNavigate } from "react-router-dom";
import { getArticleTransaction } from "../../redux/slices/transaction";
import { useTransactionSelector } from "../../redux/selector/transaction";
import * as images from "../../utilities/images";
import { toast } from "react-toastify";

const Transaction = () => {
  const toastId = useRef(null);

  document.title = "Transactions";
  let limit = 5;
  const transactionSelector = useTransactionSelector();
  const { loading } = transactionSelector;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transList, setTransList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [startIndex,setStartIndex] = useState(0)
  const [endIndex,setEndIndex] = useState(limit)

  /**
   * to get all transctions list
   * @param {*} id
   * @param {*} flag
   */

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const mergeArrays = (articleInvoice, users) => {
    return articleInvoice.map((invoice) => {
      const matchingUser = users.find(
        (user) => user?.customerId === invoice?.customer
      );
      return {
        ...invoice,
        userInfo: matchingUser ? matchingUser : null,
      };
    });
  };

  const getAllArticleTransaction = () => {
    dispatch(
      getArticleTransaction({
        cb(res) {
          if (res.status) {
            const mergedData = mergeArrays(
              res?.data?.data.articleInvoice,
              res?.data?.data?.user
            );
            setFilteredData(mergedData); // Initialize filteredData
            setTransList(mergedData);
            setTotalPage(
              Math.ceil(res?.data?.data.articleInvoice?.length / limit)
            );
          }
        },
      })
    );
  };

  /**
   * to convert time format
   * @param {*} seconds
   * @returns
   */

  const convertTimeFormat = (seconds) => {
    // Multiply by 1000 to convert seconds to milliseconds
    const milliseconds = seconds * 1000;
    const timestamp = new Date(milliseconds);

    // Define the date format options
    const dateFormatOptions = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };

    // Define the time format options without seconds
    const timeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 12-hour format
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Use the local time zone
    };

    // Format the date and time using the specified options
    const formattedDate = timestamp.toLocaleDateString(
      "en-US",
      dateFormatOptions
    );
    const formattedTime = timestamp.toLocaleTimeString(
      "en-US",
      timeFormatOptions
    );

    // Return the formatted date and time
    return `${formattedDate}, ${formattedTime}`;
  };

  /**
   * Page Change Transction list
   * @param {*} e
   * @returns
   */

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage + 1);
    let startBy = selectedPage * limit
    setStartIndex(startBy)
    setEndIndex(startBy + limit)
  };

  useEffect(() => {
    getAllArticleTransaction();
  }, []);

  useEffect(() => {
    let filtered = transList;

    if (search) {
      filtered = transList.filter((trans) => {
        const searchLower = search.toLowerCase();
        const idMatch = trans.id.toLowerCase().includes(searchLower);
        const nameMatch =
          trans?.userInfo?.userInfo &&
          `${trans?.userInfo?.userInfo?.firstName} ${trans?.userInfo?.userInfo?.lastName}`
            .toLowerCase()
            .includes(searchLower);
        const dateMatch =
          trans?.created &&
          convertTimeFormat(trans.created).includes(searchLower);
        const emailMatch =
          trans?.userInfo &&
          trans?.userInfo?.email.toLowerCase().includes(searchLower);
        const phoneMatch =
          trans?.userInfo?.userInfo?.phoneNo &&
          trans?.userInfo?.userInfo?.phoneNo.includes(searchLower); // Phone number filter
        const dialCodeMatch =
          trans?.userInfo?.userInfo?.dialCode &&
          `${trans?.userInfo?.userInfo?.dialCode}${trans?.userInfo?.userInfo?.phoneNo}`.includes(
            searchLower
          ); // Dial code + phone number filter

        // Dial code + phone number match (e.g., +1-2354678958)
        const formattedPhone = `+${trans?.userInfo?.userInfo?.dialCode}-${trans?.userInfo?.userInfo?.phoneNo}`;
        const formattedPhoneMatch = formattedPhone.includes(searchLower);
const amountMatch = trans &&
          `${trans?.amount_paid / 100 || trans?.amount }`.includes(
            searchLower
          ); 
        // // Handle the 'Subscription creation' case and fallback to publisher if description is empty
        // const descriptionMatch = trans?.description === "Subscription creation"
        //   ? "subscription".includes(searchLower)
        //   : trans?.description?.toLowerCase().includes(searchLower);

        const publisherMatch =
          trans?.description === null
            ? "publisher".includes(searchLower)
            : trans?.description?.toLowerCase().includes(searchLower);

             
        if (
          idMatch ||
          nameMatch ||
          dateMatch ||
          emailMatch ||
          phoneMatch ||
          dialCodeMatch ||
          formattedPhoneMatch ||
          amountMatch||
          // descriptionMatch ||
          publisherMatch
        ){
          return trans;
        }
      });
    }

    setFilteredData(filtered);
    // setTransList(filtered);

  }, [search, transList]);

  return (
    <>
      <div className="content-wrapper  draftSection">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="innerHeadText my-4">All Transactions</h2>
          <h5 className="total_transaction my-4">
            Total Transactions Amount : $
            {transList?.reduce(
              (accumulator, current) => accumulator + current?.amount / 100,
              0
            )}
          </h5>
        </div>

        <div className="searchSection mb-3">
          <input
            class="form-control searchInput"
            type="text"
            placeholder="Search by name & email"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <img
            src={images.searchImg}
            alt="searchImg"
            className="img-fluid searchBarIcon"
          />
        </div>
        <div className="table-responsive recentTable transactTable">
          <table class="table table-dark m-0">
            <thead>
              <tr className="recentRow">
                <th className="recentHead">Index</th>
                <th className="recentHead">Name</th>
                <th className="recentHead">Email</th>
                <th className="recentHead">Phone No.</th>
                <th className="recentHead">Payment Type</th>
                <th className="recentHead">Transaction Id</th>
                <th className="recentHead dateTimeHead">Date & Time</th>
                <th className="recentHead ">Price</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {loading ? (
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center text-light">
                      <div
                        className="spinner-grow text-light"
                        role="status"
                      ></div>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {filteredData?.length > 0 ? (
                    filteredData?.slice(startIndex, endIndex).map((value, idx) => {
                      let serialNumber = (page - 1) * limit + idx + 1;
                      return (
                        <tr key={idx}>
                          {}
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">{serialNumber}</h6>
                          </td>
                          <td
                            className="recentSubHead align-middle pointCursor d-flex align-items-center"
                            onClick={() => {
                              if (!value?.userInfo?.deletedAt) {
                                navigate(
                                  `/user-detail/${value?.userInfo?._id}`
                                );
                              } else {
                                showToast("This user has been deleted");
                              }
                            }}
                          >
                            <div className="transTable_Img me-2">
                              <img
                                src={value?.userInfo?.userInfo?.profilePhoto}
                                alt="publishImage"
                                className=" img-fluid"
                              />
                            </div>
                            <h6 className="tableSubText">
                              {value?.userInfo?.userInfo?.firstName +
                                " " +
                                value?.userInfo?.userInfo?.lastName}
                            </h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText text-lowercase">
                              {value?.userInfo?.email}
                            </h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">
                              +{value?.userInfo?.userInfo?.dialCode}-
                              {value?.userInfo?.userInfo?.phoneNo}
                            </h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">
                              {value?.description === "Subscription creation"
                                ? "Subscription"
                                : "Publisher"}
                            </h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">{value?.id}</h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">
                              {convertTimeFormat(value?.created)}
                            </h6>
                          </td>
                          <td className="recentSubHead align-middle">
                            <h6 className="tableSubText">
                              ${value?.amount_paid / 100 || value?.amount / 100}
                            </h6>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="noFoundHead">
                        <p className="text-center noFoundText no_Tickets_Txt">
                          No Transaction Found!
                        </p>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        {filteredData?.length > 0 && (
          <div className=" paginateSec">
            <ReactPaginate
              className="paginationBox mb-0"
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPage}
              previousLabel="< "
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Transaction;
