import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
};

export const socialLinksSlice = createSlice({
  name: "socialLink",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
    getAllSocialLink: (state) => {
      state.loading = true;
    },
    setGetAllSocialLink: (state) => {
      state.loading = false;
    },
    getOneSocialLink: (state) => {
      state.loading = true;
    },
    setGetOneSocialLink: (state) => {
      state.loading = false;
    },
    updateOneSocialLink: (state) => {
      state.loading = true;
    },
    setUpdateOneSocialLink: (state) => {
      state.loading = false;
    },
    activeInactiveSocialLink: (state) => {
      state.loading = true;
    },
    setActiveInactiveSocialLink: (state) => {
      state.loading = false;
    },
    clientLogos: (state) => {
      state.loading = true;
    },
    setClientLogos: (state) => {
      state.loading = false;
    },
    uploadClientLogo: (state) => {
      state.loading = true;
    },
    setUploadClientLogo: (state) => {
      state.loading = false;
    },
    addClientLogo: (state) => {
      state.loading = true;
    },
    setAddClientLogo: (state) => {
      state.loading = false;
    },
    editClientLogo: (state) => {
      state.loading = true;
    },
    setEditClientLogo: (state) => {
      state.loading = false;
    },
    deleteClientLogo: (state) => {
      state.loading = true;
    },
    setDeleteClientLogo: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onErrorStopLoad,
  getAllSocialLink,
  setGetAllSocialLink,
  getOneSocialLink,
  setGetOneSocialLink,
  updateOneSocialLink,
  setUpdateOneSocialLink,
  activeInactiveSocialLink,
  setActiveInactiveSocialLink,
  clientLogos,
  setClientLogos,
  uploadClientLogo,
  setUploadClientLogo,
  addClientLogo,
  setAddClientLogo,
  editClientLogo,
  setEditClientLogo,
  deleteClientLogo,
  setDeleteClientLogo,
} = socialLinksSlice.actions;

export default socialLinksSlice.reducer;
