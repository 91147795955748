import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commonActions";

const initialState = {
  loading: false,
};

export const articleSlice = createSlice({
  name: "article",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    onErrorStopLoad: (state) => {
        state.loading = false;
      },
      getAllArticle: (state) => {
        state.loading = true;
      },
      setGetAllArticle: (state) => {
        state.loading = false;
      },
      getOneArticle: (state) => {
        state.loading = true;
      },
      setGetOneArticle: (state) => {
        state.loading = false;
      },
      updateOneArticle: (state) => {
        state.loading = true;
      },
      setUpdateOneArticle: (state) => {
        state.loading = false;
      },
      submitPublicationArticle: (state) => {
        state.loading = true;
      },
      setSubmitPublicationArticle: (state) => {
        state.loading = false;
      }
 
    },
  });
  
  // Action creators are generated for each case reducer function
  export const {
    onErrorStopLoad,
    getAllArticle,
    setGetAllArticle,
    setGetOneArticle,
    getOneArticle,
    updateOneArticle,
    setUpdateOneArticle,
    submitPublicationArticle,
    setSubmitPublicationArticle
  } = articleSlice.actions;
  
  export default articleSlice.reducer;
  