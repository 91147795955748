import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import { onErrorStopLoad } from "../../slices/auth";
import {
  setArticleCategory,
  setCreateCategory,
  setDashboardUsers,
  setDeleteArticle,
  setDeleteUserByadmin,
  setOneCategory,
  setUpdateStatus,
  setUserList,
  setuserDetail,
  setCheckExistCategory,
  setAddSubCategory,
  setupdateOneCategory,
  setgetAllPublications,
  setDeletePublications,
  setAddPublications,
  setUploadPublicationsLogo,
  setEditPublications,
  setGetCategories,
  setGetAllRegions,
  setgetOnePublications,
} from "../../slices/dashboard";

function* getAllRegions(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.GET_ALL_REGIONS}`),
      action.payload
    );
    if (resp.status) {
      yield put(setGetAllRegions(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* checkExistCategory(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.dashboardApiPath.CHECK_EXIST_CATEGORY}`),
      action.payload
    );
    if (resp.status) {
      yield put(setCheckExistCategory(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getDashboardUsers(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.DASHBOARD_USERS}`),
      action.payload
    );
    if (resp.status) {
      localStorage.getItem("adminAuthToken");
      yield put(setDashboardUsers(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* userList(action) {
  let url = `${ApiPath.dashboardApiPath.USERS_LIST}?page=${action.payload.page}&limit=${action.payload.limit}&status=${action.payload.status}`;

  if (Boolean(action.payload.search)) {
    url = `${url}&search=${action.payload.search}`;
  }
  try {
    const resp = yield call(ApiClient.get, (action.url = url), action.payload);
    if (resp.status) {
      yield put(setUserList(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUserList({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* userDetail(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.USER_DETAIL}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setuserDetail(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setuserDetail({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* deleteUserByadmin(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.dashboardApiPath.DELETE_USER}/${action.payload.id}`)
    );

    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.msg);
  } catch (e) {
    yield put(setDeleteUserByadmin({}));
  }
}

function* updateStatus(action) {
  try {
    const resp = yield call(
      ApiClient.put,

      (action.url = ApiPath.dashboardApiPath.UPDATE_STATUS),
      action.payload
    );
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action?.res?.data?.message);
  } catch (e) {
    yield put(setUpdateStatus({}));
  }
}

function* articleCategory(action) {
  let url = `${ApiPath.dashboardApiPath.ARTICLES_CATEGORIES}`;
  if (action.payload.page) {
    url = `${url}?page=${action.payload.page}`;
  }
  if (action.payload.limit) {
    url = `${url}&limit=${action.payload.limit}`;
  }
  if (Boolean(action.payload.search)) {
    url = `${url}&search=${action.payload.search}`;
  }
  if (Boolean(action.payload.flag)) {
    url = `${url}&flag=${action.payload.flag}`;
  }

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUserList(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setArticleCategory({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* deleteArticle(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.dashboardApiPath.DELETE_CATEGORY}/${action.payload.id}`)
    );

    yield call(action.payload.cb, (action.res = resp));
    toast.success(action?.res?.data?.message);
  } catch (e) {
    yield put(setDeleteArticle({}));
  }
}

function* createCategory(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.dashboardApiPath.CREATE_CATEGORY),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action?.res?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setCreateCategory({}));
    toast.error(e.response.data.message);
  }
}

function* addSubCategory(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.dashboardApiPath.CREATE_SUB_CATEGORY),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action?.res?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAddSubCategory({}));
    toast.error(e.response.data.msg);
  }
}

function* getOneCategory(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.GET_ONECATEGORY}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setuserDetail(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setOneCategory({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* updateOneCategory(action) {
  try {
    const paramToSend = { ...action.payload };
    delete paramToSend.id;
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.dashboardApiPath.UPDATE_ONECATEGORY}/${action.payload.id}`),
      (action.payload = paramToSend)
    );
    if (resp.status) {
      yield put(setuserDetail(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action?.res?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setupdateOneCategory({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getAllPublications(action) {
  const deleteParams = { ...action.payload };
  delete deleteParams.cb;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.dashboardApiPath.GET_ALL_PUBLICATIONS),
      {
        params: deleteParams,
      }
    );
    if (resp.status) {
      yield put(setgetAllPublications(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setgetAllPublications({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* getOnePublications(action) {
  const deleteParams = { ...action.payload };
  delete deleteParams.id;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.GET_ALL_PUBLICATIONS}/${action.payload.id}`),
      (action.payload = deleteParams)
    );
    if (resp.status) {
      yield put(setgetOnePublications(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setgetOnePublications({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* deletePublications(action) {
  try {
    const paramToSend = { ...action.payload };
    delete paramToSend.id;
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.dashboardApiPath.DELETE_PUBLICATION}/${action.payload.id}`),
      (action.payload = paramToSend)
    );
    if (resp.status) {
      yield put(setDeletePublications(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action?.res?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setDeletePublications({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* addPublications(action) {
  try {
    const paramToSend = { ...action.payload };
    delete paramToSend.id;
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.dashboardApiPath.ADD_PUBLICATION),
      action.payload
    );
    if (resp.status) {
      yield put(setAddPublications(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action?.res?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAddPublications({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}
function* editPublications(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.dashboardApiPath.EDIT_PUBLICATION}/${action.payload.id}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setEditPublications(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action?.res?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setEditPublications({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}
function* uploadPublicationsLogo(action) {
  try {
    const resp = yield call(
      ApiClient.postFormData,
      (action.url = ApiPath.dashboardApiPath.UPLOAD_LOGO_PUBLICATION),
      action.payload
    );
    if (resp.status) {
      // yield put(setAddPublications(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUploadPublicationsLogo({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* GetCategories(action) {
  let url = `${ApiPath.dashboardApiPath.GET_CATEGORIES}`;
  // if (action.payload.flag) {
  //   url = `${url}?flag=${action.payload.flag}`;
  // }

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetCategories(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetCategories({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* webSaga() {
  yield all([takeLatest("web/getDashboardUsers", getDashboardUsers)]);
  yield all([takeLatest("web/userList", userList)]);
  yield all([takeLatest("web/userDetail", userDetail)]);
  yield all([takeLatest("web/adminDetail", userDetail)]);
  yield all([takeLatest("web/deleteUserByadmin", deleteUserByadmin)]);
  yield all([takeLatest("web/updateStatus", updateStatus)]);
  yield all([takeLatest("web/articleCategory", articleCategory)]);
  yield all([takeLatest("web/articleSubCategory", articleCategory)]);
  yield all([takeLatest("web/deleteArticle", deleteArticle)]);
  yield all([takeLatest("web/createCategory", createCategory)]);
  yield all([takeLatest("web/getOneCategory", getOneCategory)]);
  yield all([takeLatest("web/updateOneCategory", updateOneCategory)]);
  yield all([takeLatest("web/checkExistCategory", checkExistCategory)]);
  yield all([takeLatest("web/addSubCategory", addSubCategory)]);
  yield all([takeLatest("web/getAllPublications", getAllPublications)]);
  yield all([takeLatest("web/getOnePublications", getOnePublications)]);
  yield all([takeLatest("web/deletePublications", deletePublications)]);
  yield all([takeLatest("web/addPublications", addPublications)]);
  yield all([takeLatest("web/editPublications", editPublications)]);
  yield all([takeLatest("web/uploadPublicationsLogo", uploadPublicationsLogo)]);
  yield all([takeLatest("web/getCategories", GetCategories)]);
  yield all([takeLatest("web/getAllRegions", getAllRegions)]);
}

export default webSaga;
