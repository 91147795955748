import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLogout } from "../../redux/slices/auth";
import * as Images from "../../utilities/images";
import { useNotificateSelector } from "../../redux/selector/notification";
import moment from "moment";
import { readNotification } from "../../redux/slices/notification";
import { toast } from "react-toastify";

const NotificationModal = (props) => {
  const toastId = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationSelector = useNotificateSelector();
  console.log(notificationSelector, "notificationSelector");

  const { loading } = notificationSelector;
  const [notificationId, setNotiFicationId] = useState("");
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const readNotifyMsg = (id, notificationId, userdelete) => {
    setNotiFicationId(id);
    let params = {
      is_read: "true",
      id: id,
    };
    dispatch(
      readNotification({
        ...params,
        cb(res) {
          if (res?.data) {
            console.log(res?.data,"res?.data");
            
            if (userdelete) {
              navigate("/user-delete");
            }
            else{
              if (res?.data?.data?.type === "article_posted")
                navigate(`/article-details/${notificationId}`);
              if (
                res?.data?.data?.type === "subscription_created" ||
                res?.data?.data?.type === "User_created" ||
                res?.data?.data?.type === "subscription_canceled"
              )
                navigate(`/user-detail/${notificationId}`);

                if(res?.data?.data?.type === "articleMessage")
                  navigate(`/article-details/${notificationId}`,{
                    state:{
                      flag:"articleMessage"
                    }});
                    if(res?.data?.data?.type === "ticketMessage")
                      navigate(`/ticket-details/${notificationId}`,{
                        state:{
                          flag:"ticketMessage"
                        }});
              if (res?.data?.data?.type === "ticket_created")
                navigate(`/ticket-details/${notificationId}`);
              props.close();
            }
          }
        },
      })
    );
  };

  return (
    <>
      <div className="deleteModalsec text-right mb-3">
        <h3
          className="seeAll_Notification pointCursor"
          onClick={() => {
            navigate("/notification");
            if (props?.close) {
              props.close();
            }
          }}
        >
          See All
        </h3>
      </div>
      yarn start
      <div className="notificateSec">
        {/* {loading && !notificationId ? (
          <>
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          </>
        ) : (
          <> */}
        {notificationSelector?.notificationData?.length > 0 ? (
          notificationSelector?.notificationData
            ?.filter((item) => !item?.is_read)
            // ?.slice(0, 5)
            .map((value, index) => (
              <>
                {loading && value?._id === notificationId ? (
                  <div className="notifyList notifyLoader">
                    <div className="loaderOuter d-flex justify-content-center text-light">
                      <div
                        className="spinner-grow text-light"
                        role="status"
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      value?.is_read === false
                        ? "notifyList active notifyModal_List pointCursor"
                        : "notifyList notifyModal_List pointCursor"
                    }
                    key={index}
                    onClick={() => {
                      readNotifyMsg(
                        value?._id,
                        value?.notificationTypeId,
                        value?.user?.deletedAt
                      );
                    }}
                  >
                    <div className="notifyMsgArea">
                      <figure className="notifyUser">
                        <img
                          src={value?.user?.profilePhoto || Images.dummyProfile}
                          alt="publication Logo"
                          className="img-fluid notifyUserImg"
                        />
                        <div className="bellNotify">
                          <img
                            src={Images.lightBell}
                            alt="publication Logo"
                            className="img-fluid"
                          />
                        </div>
                      </figure>
                      <div className="notifyData">
                        <p className="userDarkPara mb-1">
                          {value?.description}
                        </p>
                        <h6 className="notifyTime">
                          {moment(value?.createdAt).fromNow()}
                        </h6>
                      </div>
                    </div>
                    <span className="unreadBtn"></span>
                  </div>
                )}
              </>
            ))
        ) : (
          <h3 className="text-center m-0 mainHead32 noNotificationsFound">
            No Notification Found!
          </h3>
        )}
        {/* </>
        )} */}
      </div>
    </>
  );
};

export default NotificationModal;
