import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";
import ApiPath from "../../../constants/apiPath";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setGetAllArticle,
  setGetOneArticle,
  setSubmitPublicationArticle,
  setUpdateOneArticle,
  setUpdatePublicationArticle,
} from "../../slices/article";
const queryString = require("query-string");

function* getAllArticle(action) {
  let data = { ...action.payload };
  delete data.cb;
  let query = queryString.stringify(data);
  let url = `${ApiPath.articleApiPath.ARTICLE_LIST}?${query}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetAllArticle(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getOneArticle(action) {
  let url = `${ApiPath.articleApiPath.ARTICLE_LIST}/${action.payload.id}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetOneArticle(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateOneArticle(action) {
  let data = { ...action.payload };
  let url = `${ApiPath.articleApiPath.UPDATE_ARTICLE_STATUS}/${data.id}`;
  delete data.id;
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = url),
      (action.payload = data)
    );
    if (resp.status) {
      yield put(setUpdateOneArticle(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* submitPublicationArticle(action) {
  let data = { ...action.payload };
  let url = `${ApiPath.articleApiPath.SUBMIT_PUBLICATION_ARTICLE}/${data.id}`;
  delete data.id;
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = url),
      (action.payload = data)
    );
    if (resp.status) {
      yield put(setSubmitPublicationArticle(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* ticketSaga() {
  yield all([takeLatest("article/getAllArticle", getAllArticle)]);
  yield all([takeLatest("article/getOneArticle", getOneArticle)]);
  yield all([takeLatest("article/updateOneArticle", updateOneArticle)]);
  yield all([takeLatest("article/submitPublicationArticle", submitPublicationArticle)]);
}

export default ticketSaga;
